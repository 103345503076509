const userCourseTypes = {
  administrator: { name: 'Administrator', level: 1 },
  coAdministrator: { name: 'Co-Administrator', level: 2 },
  authors: { name: 'Author', level: 3 },
  coAuthorsCollaborators: { name: 'co-Author/Collaborator', level: 4 },
  reviewer: { name: 'Reviewer', level: 5 },
  advanced: { name: 'Advanced Member', level: 6 },
  learnersStudents: { name: 'Member', level: 7 },
};

const userCourseRole = {
  organizer: 'organizer',
  member: 'member',
};

const getUpperLevel = (level) => Object.values(userCourseTypes).find((type) => type.level === level - 1);

const getLowerLevel = (level) => Object.values(userCourseTypes).find((type) => type.level === level + 1);

const getTypeFromId = (id) => Object.values(userCourseTypes).find((type) => type.level === id);

const getManagerLevels = () =>
  Object.values(userCourseTypes)
    .filter((t) => t.level <= userCourseTypes.coAuthorsCollaborators.level)
    .map((t) => t.level);

const getStudentLevels = () =>
  Object.values(userCourseTypes)
    .filter((t) => t.level > userCourseTypes.coAuthorsCollaborators.level)
    .map((t) => t.level);

const isAdmin = (level) => level === userCourseTypes.administrator.level;

const isManager = (level) => {
  const managerLevels = getManagerLevels();
  return managerLevels.includes(level);
};

const isStudent = (level) => {
  const studentLevels = getStudentLevels();
  return studentLevels.includes(level);
};

const getMembersTypeList = () => Object.values(userCourseTypes);

const USER_COURSE_TYPES = {
  ...userCourseTypes,
  userCourseRole,
  getMembersTypeList,
  getUpperLevel,
  getLowerLevel,
  getTypeFromId,
  getManagerLevels,
  getStudentLevels,
  isAdmin,
  isManager,
  isStudent,
};

export default USER_COURSE_TYPES;
