import domainTrackingApi from './services/domain-tracking';
import googleUserInfoApi from './services/googleUserInfo';

import downloadFileS3 from './services/downloadFile/downloadFileS3';
import fileManagerMetaApi from './services/fileManagerMetaApi';
import getYouTubeVideoDurationApi from './services/getYouTubeVideoDuration';
import { learningCommunitiesApi } from './services/learningCommunities';
import { learningCommunityMembersApi } from './services/learningCommunities/Members';
import { initialThoughtsApi } from './services/learningCommunities/initialThoughts';
import { volumeChapterApi } from './services/learningCommunities/volumeChapter';
import { notificationApi } from './services/notifications';
import organizationsApi from './services/organizations';
import getUnsplashApi from './services/unsplash';
import userApi from './services/users';
import assessment from './slices/assessment';
import audioTranscriptSlice from './slices/audioTranscriptText';
import authorizationsSlice from './slices/authorizations';
import entitySidebarSlice from './slices/entitySidebar';
import invitations from './slices/invitations';
import isLoading from './slices/isLoading';
import learningCommunity from './slices/learningCommunity';
import modalSlice from './slices/modal';
import profileSlice from './slices/profile';
import scrollToTop from './slices/scrollToTop';
import selectedFileSlice from './slices/selectedFile';

const rootReduser = {
  modalSlice,
  authorizationsSlice,
  isLoading,
  selectedFileSlice,
  profileSlice,
  learningCommunity,
  assessment,
  invitations,
  scrollToTop,
  entitySidebarSlice,
  audioTranscriptSlice,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [fileManagerMetaApi.reducerPath]: fileManagerMetaApi.reducer,
  [downloadFileS3.reducerPath]: downloadFileS3.reducer,
  [googleUserInfoApi.reducerPath]: googleUserInfoApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [domainTrackingApi.reducerPath]: domainTrackingApi.reducer,
  [getYouTubeVideoDurationApi.reducerPath]: getYouTubeVideoDurationApi.reducer,
  [getUnsplashApi.reducerPath]: getUnsplashApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [initialThoughtsApi.reducerPath]: initialThoughtsApi.reducer,
  [learningCommunitiesApi.reducerPath]: learningCommunitiesApi.reducer,
  [volumeChapterApi.reducerPath]: volumeChapterApi.reducer,
  [learningCommunityMembersApi.reducerPath]: learningCommunityMembersApi.reducer,
};

export default rootReduser;
