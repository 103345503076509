import { WS_API_ROOT } from 'configs/env-vars';

// eslint-disable-next-line import/no-extraneous-dependencies
import io from 'socket.io-client';

import { getAccessToken, getDomainTracking } from 'configs/localStorage';
import { message } from 'style-guide/ToastMessages';
import emptySplitApi from '..';

const getSocket = () =>
  io(WS_API_ROOT, {
    transports: ['websocket'],
    withCredentials: true,
  });

export const notificationApi = emptySplitApi.injectEndpoints({
  reducerPath: 'notification',
  endpoints: (build) => ({
    getNotification: build.query({
      query: (params) => ({
        url: '/notifications',
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['notifications'],
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (!arg.offset || currentCache?.totalNotificationCount !== newItems?.totalNotificationCount) {
          return newItems;
        }

        currentCache.notifications.push(...newItems.notifications);
      },
      forceRefetch: false,
      async onCacheEntryAdded(_, { cacheEntryRemoved, updateCachedData, getCacheEntry, getState, queryFulfilled }) {
        await queryFulfilled;
        const socket = getSocket();
        socket.on('unreadNotifications', (data) => {
          updateCachedData((draft) => ({
            totalNotificationCount: data.totalNotificationCount,
            unreadNotificationCount: data.unreadNotificationCount,
            notifications: [...data.newNotifications, ...draft.notifications].slice(0, 5),
          }));
        });

        socket.on('emitNotification', (data) => {
          if (parseInt(data.userId, 10) === getState().profileSlice.userData.id) {
            const nots = getCacheEntry().data.notifications;
            socket.emit('auth', {
              token: getAccessToken(),
              date: nots?.[0]?.createdAt || new Date(),
              domainId: getDomainTracking().id,
            });
          }
        });

        await cacheEntryRemoved;

        socket.off('unreadNotifications');
        socket.off('emitNotification');
      },
    }),

    getAllNotification: build.query({
      query: (params) => ({
        url: '/notifications',
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: 0,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (!arg.offset || currentCache?.totalNotificationCount !== newItems?.totalNotificationCount) {
          return newItems;
        }

        currentCache.notifications.push(...newItems.notifications);
      },
    }),

    getNotificationsSettings: build.query({
      query: (params) => ({
        url: '/notifications/settings',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => {
        const notificationsSettingsData = {};
        const notificationsSettingsList = [...response.admins, ...response.learners];

        notificationsSettingsList?.forEach((e) => {
          notificationsSettingsData[e.id] = { onTop: e.onTop, byEmail: e.byEmail };
        });
        return { notificationsSettingsData, response };
      },
      providesTags: ['notifications-settings'],
    }),

    updateNotificationSettings: build.mutation({
      query: (params) => ({
        url: '/notifications/settings',
        method: 'PUT',
        body: params,
      }),
      transformResponse: (response) => {
        message.success(response.message || 'Notification settings successfully has been updated');
        return response;
      },
      invalidatesTags: ['notifications-settings'],
    }),

    updateNotification: build.mutation({
      query: (body) => ({
        url: `/notifications/${body.id}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: ['notifications'],
    }),
  }),
});

export const {
  useGetNotificationQuery,
  useLazyGetAllNotificationQuery,
  useUpdateNotificationMutation,
  useGetNotificationsSettingsQuery,
  useUpdateNotificationSettingsMutation,
} = notificationApi;
