const collectUrlParams = (urlParams) => {
  const params = [];
  const paramsToExclude = ['page'];

  // eslint-disable-next-line no-restricted-syntax
  for (const [column, value] of urlParams.entries()) {
    if (!paramsToExclude.includes(column)) {
      params.push({ column, value });
    }
  }

  return params;
};

const params = ({ searchParams, page, domainId }) =>
  searchParams.size
    ? {
        'filter[]': [
          collectUrlParams(searchParams).reduce((accumulator, currentValue) => {
            if (!accumulator) {
              return JSON.stringify(currentValue);
            }
            return `${accumulator}&filter[]=${JSON.stringify(currentValue)}`;
          }, ''),
        ],
        page,
        domainId,
      }
    : { page, domainId };

export default params;
