import useKeyPress from 'hooks/useKeyPress';
import usePrevious from 'hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Search } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';
import EntitySearchWrapper from './style/EntitySearchWrapper';

const EntitySearch = ({ placeholder }) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { category } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const prevPathname = usePrevious(pathname);

  const searchValue = searchParams.get('q');

  const onEnter = () => {
    searchParams.set('q', value.trim());
    searchParams.set('category', category);
    const params = Object.fromEntries(searchParams);

    if (!value?.trim()) {
      searchParams.delete('q');
      searchParams.delete('category');
      setSearchParams(searchParams);
      return;
    }

    if (value?.trim() && isFocused) {
      setSearchParams(params);
    }
  };

  useEffect(() => {
    if (pathname !== prevPathname) {
      setValue('');
    }
  }, [pathname, prevPathname, searchParams, searchValue, setSearchParams]);

  useKeyPress({
    keyFn: { Enter: { fn: onEnter }, NumpadEnter: { fn: onEnter } },
    event: 'keydown',
  });

  return (
    <EntitySearchWrapper>
      <Input
        placeholder={placeholder}
        Prefix={Search}
        className='search-input'
        onChange={(e) => setValue(e.target.value)}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </EntitySearchWrapper>
  );
};

export default EntitySearch;
