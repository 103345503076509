import { useCallback, useEffect, useState } from 'react';
import CheckBox from 'style-guide/CheckBox';
import { Input } from 'style-guide/Input';
import Text from 'style-guide/Typography/Text';

const LinkInput = ({ editor, linkModalVisible, setLinkModalVisible, isFixedMenu }) => {
  const [linkValue, setLinkValue] = useState('');

  const [isLinkTargetChecked, setIsLinkTargetChecked] = useState(false);

  const isLinkActive = editor?.isActive('link');
  const hasLinkValue = editor?.getAttributes('link');

  const onLinkClose = useCallback(() => {
    setLinkModalVisible(false);
    setIsLinkTargetChecked(false);
    !isFixedMenu ? setLinkValue('') : null;
  }, [isFixedMenu, setLinkModalVisible]);

  useEffect(() => {
    if (linkModalVisible) {
      editor?.on('selectionUpdate', onLinkClose);
    }

    return () => {
      if (linkModalVisible) {
        editor?.off('selectionUpdate', onLinkClose);
      }
    };
  }, [editor, linkModalVisible, onLinkClose]);

  const onInsertLinkSuccess = (href) => {
    setLinkModalVisible(false);
    if (href === null) {
      return;
    }

    if (href === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
    editor
      ?.chain()
      .focus()
      ?.toggleLink?.({ href, target: isLinkTargetChecked ? '_blank' : '_self' })
      .run();
    editor?.chain().focus().run();
  };

  useEffect(() => {
    setIsLinkTargetChecked(hasLinkValue?.target === '_blank');
    setLinkValue(isLinkActive ? hasLinkValue?.href : '');
  }, [hasLinkValue?.href, hasLinkValue?.target, isLinkActive]);

  return (
    <>
      <div className='d-flex align-items-center'>
        <div>
          <CheckBox
            checked={isLinkTargetChecked}
            onChange={(e) => {
              setIsLinkTargetChecked(e.target.checked);
              editor
                ?.chain()
                .focus()
                ?.toggleLink?.({ target: e.target.checked ? '_blank' : '_self' })
                .run();
            }}
          />
        </div>
        <Text $variant={1} className='ml--8'>
          Open a new tab
        </Text>
      </div>
      <Input
        className='pt--4'
        value={linkValue}
        onChange={(e) => setLinkValue(e.target.value)}
        onBlur={() => setLinkModalVisible(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onInsertLinkSuccess(e.target.value, isLinkTargetChecked);
          }
        }}
      />
    </>
  );
};

export default LinkInput;
