/* eslint-disable no-param-reassign */
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_API_KEY } from 'configs/env-vars';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'style-guide/Button';
import { Plus } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';
import styled from 'styled-components';
import STRING_UTILS from '../../common/string-utils';
import { addBlankInContent, extended_valid_elements, handleEditorChange } from './handleEditorChange';
import content_style from './style/content_style';

const TinymceBlankWrapper = styled.div`
  padding-bottom: 16px;
  .tox-tinymce {
    height: ${(props) => props.$height + 4}px !important;
    border-radius: 0px;
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
  }
`;
const TinymceBlank = ({
  init = {},
  onEditorChange,
  onChange,
  value,
  multiple,
  watch,
  setValue,
  label,
  reset,
  maxLength,
  blankCharactersCount,
  ...props
}) => {
  const editorRef = useRef(null);
  const [blankLength, setBlankLength] = useState(0);
  const [height, setHeight] = useState(() => (multiple ? 144 : 32));
  const [count, setCount] = useState(0);
  const { htmlToText } = STRING_UTILS;
  const addBlankDisabled = multiple ? blankLength >= 5 : blankLength >= 1;
  const [searchParams] = useSearchParams();
  const assessmentType = searchParams.get('assessmentType');
  const textLength = htmlToText(value).length;

  const textWithBlanksLength = useCallback(
    (text, blanks) => htmlToText(text).length - blankCharactersCount * blanks + blanks,
    [blankCharactersCount, htmlToText]
  );

  const isDeleteCase = (e) =>
    e?.target.className?.baseVal === 'blank-close-icon' || e.target.className?.baseVal === 'icon';

  const isLessMaxLength = (editor) =>
    textWithBlanksLength(value, blankLength) < maxLength || htmlToText(editor.getContent()).length < textLength;

  useEffect(() => {
    reset?.((formValues) => ({ ...formValues, hasBlank: !!blankLength }));
  }, [blankLength, reset]);

  useEffect(() => {
    setHeight(multiple ? 144 : 32);
    setBlankLength(0);
  }, [assessmentType, multiple]);

  useEffect(() => {
    const editorContent = editorRef.current?.getContent();

    if (editorContent && textWithBlanksLength(value, blankLength) < maxLength) {
      onChange(editorContent);
    }
    setCount(textWithBlanksLength(value, blankLength));
  }, [blankLength, maxLength, onChange, textWithBlanksLength, value]);

  const onClick = (e, editor) => {
    if (isDeleteCase(e)) {
      const document = editor.dom.doc;
      const deletedElementId = e.target.getAttribute('data-index');
      const parentNode = document.getElementById(`${deletedElementId}_bl`);
      editor.dom.remove(parentNode);
      const content = editor.getContent();
      onChange?.(content);
    }
  };

  return (
    <>
      <TinymceBlankWrapper $height={height}>
        {label || maxLength ? (
          <label required className='d-flex flex-row justify-content-between'>
            <Text $variant={1} color={({ theme }) => theme.colors.dark[80]}>
              {label}
            </Text>
            {maxLength ? (
              <Text $variant={1} color={({ theme }) => theme.colors.dark[80]}>
                {count}/{maxLength}
              </Text>
            ) : null}
          </label>
        ) : null}
        <Editor
          onInit={(_evt, editor) => {
            editorRef.current = editor;
            if (value) {
              editor?.execCommand('mceSetContent', false, value);
              handleEditorChange({
                editor,
                onChange,
                blankLength,
                setBlankLength,
                setHeight,
                reset,
                watch,
                multiple,
              });
            }
          }}
          onEditorChange={(content, editor) => {
            if (isLessMaxLength(editor)) {
              onEditorChange?.(content, editor);
            }
          }}
          onClick={onClick}
          onChange={(_e, editor) => {
            if (isLessMaxLength(editor)) {
              handleEditorChange({
                editor,
                onChange,
                blankLength,
                setBlankLength,
                setHeight,
                reset,
                watch,
                multiple,
              });
            }
          }}
          onKeyDown={(_e, editor) => {
            if (isLessMaxLength(editor)) {
              handleEditorChange({
                editor,
                onChange,
                blankLength,
                setBlankLength,
                setHeight,
                reset,
                watch,
                multiple,
              });
            }
          }}
          {...props}
          value={value}
          apiKey={TINYMCE_API_KEY}
          init={{
            height,
            selector: 'textarea',
            plugins: 'autoresize',
            mode: 'exact',
            menubar: false,
            toolbar: false,
            statusbar: false,
            placeholder: 'Write here',
            content_style,
            extended_valid_elements,
            paste_preprocess: (editor, args) => {
              const document = editor.dom.doc;
              const blanksCount = document.querySelectorAll('p > [id*="bl"]').length;
              const textToPaste = htmlToText(args.content);
              const newTextLength = textToPaste.length + textWithBlanksLength(editor.getContent(), blanksCount);
              if (newTextLength > maxLength) {
                const remainder = newTextLength - maxLength;
                const sliceOfText = textToPaste.slice(0, textToPaste.length - remainder);
                args.content = sliceOfText;
              }
            },
            ...init,
          }}
        />{' '}
      </TinymceBlankWrapper>
      <Button
        prefix={<Plus />}
        variant='link'
        disabled={addBlankDisabled || count === maxLength}
        onClick={() => {
          if (multiple) {
            const correctAnswers = watch('answer.correctAnswers');
            setValue('answer.correctAnswers', [...correctAnswers, { name: `${blankLength + 1}_bl`, answer: '' }]);
          }
          addBlankInContent(editorRef.current, blankLength + 1, multiple);
        }}
      >
        Add Blank
      </Button>
    </>
  );
};
export default TinymceBlank;
