const handleFocus = (e, setCurrentStep, stepName, currentStep) => {
  if (currentStep !== stepName) {
    e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setCurrentStep(stepName);
  }
};

export const steps = [
  { step: 'Naming', nodeId: 'naming' },
  { step: 'Branding', nodeId: 'branding' },
  { step: 'Audience settings', nodeId: 'audience' },
  { step: 'Pricing', nodeId: 'pricing' },
];

export const formStepsId = {
  naming: 'naming',
  branding: 'branding',
  audience: 'audience',
  pricing: 'pricing',
};
export const stepNumbers = {
  oneMore: 1,
  last: 3,
};

export default handleFocus;
