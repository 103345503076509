import Dialog from 'rc-dialog';
import { Close } from 'style-guide/Icons';
import styled from 'styled-components';
import './style.css';

const getModalPosition = ({ $top }) => {
  if ($top) {
    return `top: ${$top}px;
    transform: translate(-50%,  0%)`;
  }
  return `top: 50%;
  transform: translate(-50%,  -50%)`;
};

const Modal = styled(Dialog)`
  &.rc-dialog {
    position: absolute;
    left: 50%;
    ${(props) => getModalPosition(props)}
  }
`;

Modal.defaultProps = {
  closeIcon: <Close className='closeIcon' />,
};

export default Modal;
