import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { invalidateTags } from 'store/redux-toolkit-common';
import { useRemoveDepartmentMutation } from 'store/services/departments';
import { useAddFavoriteCardMutation, useRemoveFavoriteCardMutation } from 'store/services/favorites';
import useEntityClone from 'store/services/helperHooks/useEntityClone';
import { useRemoveOrganizationMutation } from 'store/services/organizations';

import { ENTITY_TYPES, STRING_UTILS, USER_COURSE_TYPES, USER_PERMISSION } from 'common';
import { getOldEntitiesType } from 'common/entity-types';
import CARD_OPTIONS from 'constants/DropdownOptions/card';

import Button from 'style-guide/Button';
import DividerWithText from 'style-guide/DividerWithText';
import DropdownV2 from 'style-guide/DropdownV2';
import { NewHeart, NewHeartFilled, Settings } from 'style-guide/Icons';
import Image from 'style-guide/Image';
import UserAvatar from 'style-guide/NewUserAvatar';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

import { deleteEntityModal } from 'utils/deleteModal';
import { toAwsLibraryImage, toAwsOrganizationImage, toAwsUserImage } from 'utils/images';

import { getUserData, getUserIsAuth } from 'store/selectors/user';
import EntityAdminFooter from './AdminFooter';
import EntityJoinedUser from './LearnerFooter/JoinedUser';
import EntityNotJoinedUser from './LearnerFooter/NotJoinedUser';
import EntityWrapper from './style/EntityWrapper';

const { LEARNING_COMMUNITY, DEPARTMENT, library } = ENTITY_TYPES;
const { organizer, member } = USER_COURSE_TYPES.userCourseRole;
const { cleanUpPrice } = STRING_UTILS;

const EntityCard = ({ data, entityType }) => {
  const {
    profileImage,
    name,
    shortDescription,
    coursesCount,
    librariesCount,
    userName,
    authorProfileImage,
    updatedAt,
    id,
    urlName,
    user_libraries,
    user_organizations,
    userType,
    rating,
    paymentTypeId,
    areParentEntitiesFree,
    accessTypeId,
    price,
    accessCodeResalePrice,
    user_favorite_library,
    user_favorite_organization,
  } = data || {};

  const [isFavorite, setIsFavorite] = useState(user_favorite_library || user_favorite_organization);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUserData);
  const isCollection = entityType === DEPARTMENT.key;

  const [addFavoriteCard] = useAddFavoriteCardMutation();
  const [removeFavoriteCard] = useRemoveFavoriteCardMutation();
  const [deleteCollection, { data: actionResponse }] = useRemoveDepartmentMutation();
  const [deleteOrganization, { data: actionOrgResponse }] = useRemoveOrganizationMutation();

  const entityCount = `${coursesCount ?? librariesCount} ${
    entityType === DEPARTMENT.key ? LEARNING_COMMUNITY.key : DEPARTMENT.key
  }`;
  const userEntities = user_libraries || user_organizations;

  const entityHomePage = `/${entityType}/${id}/${urlName}/${organizer}/homepage`;

  const isJoined = !!userEntities?.length;

  const hasEditAccess = USER_PERMISSION.hasPermission(
    library,
    USER_PERMISSION._LibraryViewManage,
    userEntities?.[0]?.userTypeId
  );
  const role = hasEditAccess ? organizer : member;
  const entityTypeUrl = `/${entityType}/${id}/${urlName}/${role}/homepage`;
  const isAuth = useSelector(getUserIsAuth);

  const hasEditPermission = USER_PERMISSION.hasPermission(
    library,
    USER_PERMISSION._LibraryManage,
    userEntities?.[0]?.userTypeId
  );

  const addToFavorite = () => {
    addFavoriteCard({ userId: user.id, entityId: id, type: getOldEntitiesType(entityType)?.plural }).then((res) =>
      setIsFavorite(res.data)
    );
  };

  const removeFromFavorite = () => {
    removeFavoriteCard({ favoriteId: isFavorite?.id, type: getOldEntitiesType(entityType).plural }).then(() =>
      setIsFavorite(null)
    );
  };
  const handleClone = useEntityClone({ data, id, entityType });

  const dropDownHandleClick = (fieldValue) => {
    if (fieldValue === 'clone') return handleClone();
    if (fieldValue === 'delete') {
      const deleteEntity = entityType === DEPARTMENT.key ? deleteCollection : deleteOrganization;
      return deleteEntityModal(deleteEntity, id, dispatch);
    }
    return navigate(fieldValue);
  };

  useEffect(() => {
    if (actionResponse?.success || actionOrgResponse?.success) {
      dispatch(invalidateTags([entityType]));
    }
  }, [actionOrgResponse?.success, actionResponse, dispatch, entityType]);

  const handleCardOptions = useMemo(
    () => CARD_OPTIONS(id, urlName, userName, entityType, hasEditPermission),
    [entityType, hasEditPermission, id, urlName, userName]
  );
  const entityHomePageUrl = `/${entityType}/${id}/${urlName}/${role}/homepage`;

  return (
    <EntityWrapper className='p--16'>
      <div className='row'>
        <div className='col-10'>
          <Button to={entityHomePageUrl} variant='text' className='p--0 image-button'>
            <Image
              $width='50px'
              $height='50px'
              src={isCollection ? toAwsLibraryImage(profileImage) : toAwsOrganizationImage(profileImage)}
              alt='no image'
              className='entity-profile-photo'
            />
            <Title variant={5} className='ml--8 entity-title'>
              {name}
            </Title>
          </Button>
        </div>
        {isAuth && !isJoined ? (
          <div className='col-2 d-flex align-items-center justify-content-end'>
            <Button onClick={isFavorite ? removeFromFavorite : addToFavorite} variant='link'>
              {isFavorite ? (
                <NewHeartFilled width={30} height={30} className='full-heart' />
              ) : (
                <NewHeart className='empty-heart' width={30} height={30} />
              )}
            </Button>
          </div>
        ) : null}
        {hasEditAccess ? (
          <div className='col-2 d-flex align-items-center justify-content-end'>
            <DropdownV2 options={handleCardOptions} space={2} onClickItem={dropDownHandleClick}>
              <Settings width={16} height={16} />
            </DropdownV2>
          </div>
        ) : null}
      </div>
      <Button to={entityHomePageUrl} variant='text' className='p--0 navigate-button'>
        <Text $variant={5} className='pv--16 d-flex entity-short-description' color={theme().colors.gray[20]}>
          {shortDescription}
        </Text>
        <Text $variant={2} className='d-block course-data' color={theme().colors.gray[20]}>
          {entityCount}
        </Text>
      </Button>
      <div className='pt--16 user-image-container'>
        <UserAvatar
          imgSrc={authorProfileImage ? toAwsUserImage(authorProfileImage) : null}
          type='default'
          label={userName}
        />
      </div>
      <DividerWithText className='mv--16' />
      {hasEditAccess ? (
        <EntityAdminFooter
          lastChange={updatedAt}
          url={entityHomePage}
          price={cleanUpPrice({ string: price, symbol: '$' })}
          accessCodeResalePrice={accessCodeResalePrice}
        />
      ) : null}
      {!hasEditAccess && isJoined ? <EntityJoinedUser userType={userType} url={entityTypeUrl} /> : null}
      {!hasEditAccess && !isJoined ? (
        <EntityNotJoinedUser
          id={id}
          urlName={urlName}
          price={price}
          areParentEntitiesFree={areParentEntitiesFree}
          rating={rating}
          type={entityType}
          accessTypeId={accessTypeId}
          paymentTypeId={paymentTypeId}
          accessCodeResalePrice={accessCodeResalePrice}
        />
      ) : null}
    </EntityWrapper>
  );
};

export default EntityCard;
