import { createSlice } from '@reduxjs/toolkit';

const entitySidebarSlice = createSlice({
  name: 'entitySidebar',
  initialState: { isOpen: false },
  reducers: {
    openSidebar: (state) => {
      state.isOpen = true;
    },
    closeSidebar: (state) => {
      state.isOpen = false;
    },
    toggleEntitySidebar: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleEntitySidebar, openSidebar, closeSidebar } = entitySidebarSlice.actions;
export default entitySidebarSlice.reducer;
