import { useEffect, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Categories from 'Components/Categories';

import { ENTITY_TYPES } from 'common';
import EXPLORE_OPTIONS from 'constants/DropdownOptions/explore';
import PATHS from 'constants/paths';
import usePlatform from 'hooks/usePlatform';

import { useGetHighestPriceQuery } from 'store/services/price';
import Button from 'style-guide/Button';
import { Close } from 'style-guide/Icons';
import SliderComponent from 'style-guide/Slider/Slider';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import FilterWrapper from './style/FilterWrapper';

const filterParams = {
  price: 'Price',
  rating: 'Rating',
  priceMinValue: '$0',
  priceMaxValue: '$1000',
  ratingMinValue: 0,
  ratingMaxValue: 5,
};

const Filter = ({ setIsOpenFilterSection }) => {
  const { isMobile, isTablet } = usePlatform();
  const { category: categories } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: entitiesMaxPrice, isLoading } = useGetHighestPriceQuery({ entitiesMaxPrice: true });

  const [searchParams, setSearchParams] = useSearchParams();
  const authorizationsSlice = useSelector((state) => state?.authorizationsSlice);
  const { isAuth: isLoggedIn } = authorizationsSlice;

  const maxPrice = searchParams.get('max-price');

  const minPrice = searchParams.get('min-price');
  const rating = searchParams.get('rating');
  const ratingValue = useMemo(() => (rating ? rating.split('-') : []), [rating]);
  const { control, setValue } = useForm({ mode: 'onChange' });

  const { field: priceField } = useController({
    control,
    name: 'price',
    defaultValue: { max: '', min: '' },
  });

  const { field: ratingField } = useController({
    control,
    name: 'rating',
    defaultValue: [],
  });

  useEffect(() => {
    setValue('rating', ratingValue);
    setValue('price', { max: maxPrice || '', min: minPrice || '' });
  }, [maxPrice, minPrice, ratingValue, setValue]);

  const changeCategory = (category) => {
    const url = generatePath(PATHS.EXPLORE, { category });
    if (url !== pathname) {
      navigate({
        pathname: generatePath(PATHS.EXPLORE, { category }),
        search: createSearchParams(searchParams).toString(),
      });
    }
  };

  const resetFields = () => {
    priceField.onChange({ max: '', min: '' });
    ratingField.onChange([]);
    const search = searchParams.get('q');
    setSearchParams(search ? { q: search } : {});
    setIsOpenFilterSection?.(false);
  };

  const changeEntityPrice = (priceParams) => {
    priceField.onChange({ ...priceField.value, min: priceParams[0], max: priceParams[1] });
    searchParams.set('min-price', priceParams[0]);
    searchParams.set('max-price', priceParams[1]);
    navigate({
      pathname: generatePath(PATHS.EXPLORE, { category: 'all' }),
      search: createSearchParams(searchParams).toString(),
    });
    setIsOpenFilterSection?.(false);
  };

  const toggleRatingOption = (value) => {
    if (value.length) {
      ratingField.onChange([...ratingField.value, value]);
      searchParams.set('rating', value.join('-'));
    }
    navigate({
      pathname: generatePath(PATHS.EXPLORE, { category: 'all' }),
      search: createSearchParams(searchParams).toString(),
    });
  };

  const dropdownOptions = isLoggedIn ? EXPLORE_OPTIONS : EXPLORE_OPTIONS.filter((el) => el.value !== 'favorites');

  return (
    <FilterWrapper className='filter-root p--16'>
      {isMobile || isTablet ? (
        <div className='d-flex justify-content-between align-items-center pb--28'>
          <span className='filter'>Filter</span>
          <Close className='close-icon' onClick={() => setIsOpenFilterSection?.(false)} />
        </div>
      ) : null}
      <div className='mb--24'>
        <Title variant={5} className='mb--24'>
          Categories
        </Title>
        <Categories
          activeCategory={categories}
          changeCategory={changeCategory}
          options={dropdownOptions}
          searchParams={searchParams}
        />
      </div>
      <div className='d-flex justify-content-between align-items-center mb--12'>
        <Title variant={5}>Filters</Title>
        <Button variant='link' onClick={resetFields}>
          Reset
        </Button>
      </div>
      {categories !== ENTITY_TYPES.MEMBER.key && !isLoading ? (
        <>
          <Text $variant={2} color={theme().colors.gray[10]} weight={600} className='mb--16'>
            {filterParams.price}
          </Text>
          <SliderComponent
            className='mv--16'
            defaultValue={[minPrice, maxPrice || entitiesMaxPrice]}
            range
            min={0}
            max={entitiesMaxPrice}
            value={!maxPrice ? [0, entitiesMaxPrice] : [minPrice, maxPrice]}
            onChange={(priceValues) => changeEntityPrice(priceValues)}
            tooltip
          />
          <div className='d-flex justify-content-between align-items-center mb--12'>
            <Text $variant={3}>{filterParams.priceMinValue}</Text>
            <Text $variant={3} color={theme().colors.gray[10]}>
              ${entitiesMaxPrice || ''}
            </Text>
          </div>
          <Text $variant={2} color={theme().colors.gray[10]} weight={600} className='mb--16'>
            {filterParams.rating}
          </Text>
          <SliderComponent
            className='mv--16'
            defaultValue={[ratingValue[0] || 0, ratingValue[1] || filterParams.ratingMaxValue]}
            range
            min={filterParams.ratingMinValue}
            max={filterParams.ratingMaxValue}
            value={
              !ratingValue[1]
                ? [filterParams.ratingMinValue, filterParams.ratingMaxValue]
                : [ratingValue[0], ratingValue[1]]
            }
            onChange={(price) => toggleRatingOption(price)}
            tooltip
          />
          <div className='d-flex justify-content-between align-items-center mb--12'>
            <Text $variant={3}>{filterParams.ratingMinValue}</Text>
            <Text $variant={3} color={theme().colors.gray[10]}>
              {filterParams.ratingMaxValue}
            </Text>
          </div>
        </>
      ) : null}
    </FilterWrapper>
  );
};

export default Filter;
