import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 1050;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.boxShadow[15]};
  background-color: ${({ theme }) => theme.colors.white[100]};
  .header-button {
    white-space: nowrap;
    &:focus {
      background-color: ${({ theme }) => theme.colors.primary[50]};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.primary[50]};
    }
  }

  .heart-icon,
  .bell-icon,
  .hamburger-icon,
  .close-icon {
    path {
      fill: ${({ theme }) => theme.colors.boxShadow[50]};
    }
  }

  .close-icon {
    path {
      fill: ${({ theme }) => theme.colors.primary[600]};
    }
  }

  .header-icon {
    &:focus {
      background-color: transparent;
      .bell-icon {
        path {
          fill: ${({ theme }) => theme.colors.primary[600]};
        }
      }
    }
  }

  .active-icon {
    path {
      fill: ${({ theme }) => theme.colors.primary[600]};
    }
  }

  .rc-dropdown-open {
    .explore {
      background-color: ${({ theme }) => theme.colors.primary[50]};
    }
  }

  .peer-tree-logo {
    margin-right: 30px;
    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      margin-right: 12px;
    }
  }
  .hippo-logo {
    height: 44px;
    margin-right: 12px;
    @media only screen and (max-width: ${SCREEN_SIZES.LG}px) {
      margin-right: 0;
    }
  }
  .vanderbilt-logo {
    height: 40px;
  }
  .political-islam-logo {
    height: 40px;
  }
  .fsc-logo {
    height: 44px;
    margin-right: 12px;
  }
  .csm-logo {
    height: 28px;
    margin-right: 32px;
  }
  .mlk-logo {
    height: 48px;
    margin-right: 24px;
  }
  .nucleus-logo {
    width: 160px;
    margin-right: 12px;
    padding-top: 4px;
  }
  .pear-logo {
    height: 44px;
    margin-right: 12px;
  }
  .ecf-logo {
    height: 52px;
    margin-right: 12px;
  }
  .workvibes-logo {
    height: 40px;
    margin-right: 24px;
    @media only screen and (max-width: ${SCREEN_SIZES.SM}px) {
      height: 24px;
      margin-right: 8px;
    }
  }
  .tvg-logo,
  .me-logo,
  .fba-logo {
    height: 40px;
    margin-right: 12px;
  }

  /* Dark mode */

  &.dark-mode {
    background-color: ${({ theme }) => theme.colors.darkBackground[100]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkBackground[100]};
    .header-button {
      color: ${({ theme }) => theme.colors.white[100]};
      &:hover {
        background-color: ${({ theme }) => theme.colors.boxShadow[30]};
      }
      &:focus {
        background-color: ${({ theme }) => theme.colors.boxShadow[50]};
      }
    }

    .rc-dropdown-open {
      .explore {
        background-color: ${({ theme }) => theme.colors.boxShadow[50]};
      }
    }

    .header-icon {
      &:hover {
        background-color: ${({ theme }) => theme.colors.boxShadow[30]};
      }
      &:focus {
        background-color: transparent;
      }
    }

    .explore {
      color: ${({ theme }) => theme.colors.white[100]};
      .down {
        path {
          fill: ${({ theme }) => theme.colors.white[100]};
        }
      }
    }

    .heart-icon,
    .bell-icon,
    .hamburger-icon {
      path {
        fill: ${({ theme }) => theme.colors.white[100]};
      }
    }

    .linkButton {
      color: ${({ theme }) => theme.colors.white[100]};
    }

    .search-box {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.white[100]};

      input {
        color: ${({ theme }) => theme.colors.white[100]};
      }

      .search-input {
        ::placeholder {
          color: ${({ theme }) => theme.colors.white[25]};
        }
      }

      .search-icon {
        path {
          fill: ${({ theme }) => theme.colors.white[100]};
        }
      }
    }
  }

  /* ToDo: Remove this style after adding secondary header */

  .finish-button {
    width: 96px;
  }

  /* ToDo: Remove search box styles after adding secondary searches */

  .search-box {
    width: 300px;
    @media only screen and (min-width: ${SCREEN_SIZES.XL}px) {
      width: 400px;
    }
    @media only screen and (min-width: ${SCREEN_SIZES.MD}px) and (max-width: ${SCREEN_SIZES.LG}px) {
      width: 300px;
      margin-left: 0;
    }
  }

  .search-box {
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.dark[25]};
    &.focused {
      background-color: transparent;
      .search-input {
        ::placeholder {
          color: transparent;
        }
      }
    }

    input {
      color: ${({ theme }) => theme.colors.dark[100]};
    }

    .search-input {
      ::placeholder {
        color: ${({ theme }) => theme.colors.dark[60]};
      }
    }

    .search-icon {
      path {
        fill: ${({ theme }) => theme.colors.dark[100]};
      }
    }
  }
`;

export default HeaderWrapper;
