import { generateJSON } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import { STRING_UTILS } from 'common';
import { useEffect, useMemo, useState } from 'react';
import { isJSON } from 'utils/helperFunctions';
import Menu from '../Components/Menu';
import LinkInput from '../Components/Menu/LinkInput';
import extensions from '../Extensions';
import { EditorProvider } from '../style';
import fixedMenuActions from './Action';
import SimplifiedEditorWrapper from './style/SimplifiedEditorWrapper';

const SimplifiedEditor = ({ content, onChange, placeholder, editable = true, showOnlyText, className }) => {
  const { cleanUpTinymceHtmlForTipTap } = STRING_UTILS;

  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [editor, setEditor] = useState();

  const formatData = useMemo(
    () => (isJSON(content) ? JSON.parse(content) : generateJSON(cleanUpTinymceHtmlForTipTap(content), extensions)),
    [cleanUpTinymceHtmlForTipTap, content]
  );

  const [editorData, setEditorData] = useState(formatData);

  const hasLinkValue = editor?.getAttributes('link');

  const actions = useMemo(() => (editor ? fixedMenuActions({ editor, setLinkModalVisible }) : []), [editor]);
  const showLinkInput = (editor?.isFocused && hasLinkValue?.href) || linkModalVisible;

  useEffect(() => {
    if (editor && JSON.stringify(editor?.getJSON()) !== content) {
      editor?.commands.setContent(formatData);
    }
  }, [content, editor, formatData]);

  useEffect(() => {
    if (showOnlyText) {
      setEditorData(editor?.getText(content));
    }
  }, [content, editor, showOnlyText]);

  return (
    <SimplifiedEditorWrapper className={className}>
      {editable ? (
        <div className='menu-block pv--8'>
          <Menu actions={actions} className='d-flex align-items-center' />
          {showLinkInput ? (
            <LinkInput editor={editor} setLinkModalVisible={setLinkModalVisible} linkModalVisible={linkModalVisible} />
          ) : null}
        </div>
      ) : null}
      <EditorProvider
        content={editorData}
        editable={editable}
        extensions={[
          Placeholder.configure({
            placeholder,
          }),
          ...extensions,
        ]}
        onCreate={(a) => {
          setEditor?.(a.editor);
        }}
        onUpdate={(a) => {
          setEditor?.(a.editor);
          const editorValue = editor.getJSON();
          onChange?.(JSON.stringify(editorValue));
        }}
      />
    </SimplifiedEditorWrapper>
  );
};

export default SimplifiedEditor;
