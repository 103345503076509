import styled, { css } from 'styled-components';
import Editor from './EditorProvider';

const styles = css`
  img,
  video,
  iframe {
    max-width: 100%;
    width: 100%;
  }
  .video-completed-status {
    width: 100% !important;
  }
  .container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  .responsive-iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
  mark {
    border-radius: 0.25em;
    box-decoration-break: clone;
    padding: 0.125em 0;
  }

  p {
    line-height: 1.6;
    margin-bottom: 16px;
  }
  .is-empty::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  blockquote {
    p {
      display: inline;
      &::after {
        content: '"';
      }
      &::before {
        content: '"';
      }
    }
  }
`;

const EditorContentWrapper = styled.div`
  ${styles}
`;

const EditorProvider = styled(Editor)`
  min-height: 50vh;
  max-height: 100%;
  &:focus-visible {
    outline: none;
    border: none;
  }
  &.editor {
    ${styles}
  }
`;

export { EditorContentWrapper, EditorProvider };
