import { ENTITY_TYPES } from 'common';
import DASHBOARD_OPTIONS from './dashboard';

const EXPLORE_OPTIONS = [
  ...DASHBOARD_OPTIONS,
  {
    name: 'Favorites',
    value: 'favorites',
  },
  {
    name: 'All Categories',
    value: 'all',
  },
  {
    name: ENTITY_TYPES.MEMBER.plural,
    value: ENTITY_TYPES.MEMBER.key,
  },
];

export default EXPLORE_OPTIONS;
