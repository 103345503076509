import { infiniteScrollMiddleware, transformErrorResponse } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

export const learningCommunitiesApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    learningCommunities: build.mutation({
      query: (params) => ({
        url: '/courses',
        method: 'GET',
        params,
      }),
    }),

    getLearningCommunities: build.query({
      query: (params) => ({
        url: '/courses',
        method: 'GET',
        params,
      }),
      ...infiniteScrollMiddleware,
      providesTags: ['learning-communities'],
    }),

    getOrganizerLearningCommunities: build.query({
      query: (params) => ({
        url: '/courses',
        method: 'GET',
        params,
      }),
      ...infiniteScrollMiddleware,
      providesTags: ['learning-communities'],
    }),

    getLearningCommunitiesInvitation: build.query({
      query: (params) => ({
        url: '/courses',
        method: 'GET',
        params,
      }),
    }),

    getLearningCommunity: build.query({
      query: (params) => ({
        url: `/courses/${params.urlName}/`,
        method: 'GET',
        params,
      }),
      transformErrorResponse,
      providesTags: ['learning-community', 'enroll-user'],
    }),

    createLearningCommunities: build.mutation({
      query: (params) => ({
        url: '/courses',
        method: 'POST',
        body: params,
      }),
      transformErrorResponse,
    }),

    updateLearningCommunities: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      transformErrorResponse,
    }),

    removeLearningCommunities: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}`,
        method: 'DELETE',
        body: params,
        params: {
          id: params.id,
          cascade: false,
        },
      }),
      transformErrorResponse,
      invalidatesTags: ['learning-communities'],
    }),

    updateLearningCommunitiesBookmark: build.mutation({
      query: (params) => ({
        url: `courses/${params.courseId}/positions`,
        method: 'PUT',
        body: params,
      }),
    }),

    getLearningCommunityBookmark: build.query({
      query: (params) => ({
        url: `courses/${params.id}/positions/0`,
        method: 'GET',
      }),
    }),

    getLearningCommunityAuthor: build.query({
      query: (params) => ({
        url: `courses/${params.urlName}/members`,
        method: 'GET',
        params,
      }),
      transformResponse: (result) => result.rows[0],
    }),
  }),
});

export const {
  useGetLearningCommunityAuthorQuery,
  useGetLearningCommunitiesQuery,
  useLazyGetLearningCommunitiesQuery,
  useLazyGetLearningCommunitiesInvitationQuery,
  useGetLearningCommunityQuery,
  useLazyGetLearningCommunityQuery,
  useLearningCommunitiesMutation,
  useRemoveLearningCommunitiesMutation,
  useCreateLearningCommunitiesMutation,
  useUpdateLearningCommunitiesMutation,
  useGetLearningCommunityBookmarkQuery,
  useUpdateLearningCommunitiesBookmarkMutation,
  useLazyGetOrganizerLearningCommunitiesQuery,
} = learningCommunitiesApi;

export default { learningCommunitiesApi };
