import PATHS from 'constants/paths';
import { Suspense, lazy } from 'react';

import PageSpin from 'style-guide/Spin/PageSpin';
import AppRouterLayout from '../AppRouterLayout';
import authenticatedUserRouter from './authenticated';
import entitiesHomepageRoutes from './entityPagesRoute/entitiesHomepageRoutes';
import notAuthenticatedRoute from './notAuthenticated';
import redirectRoutes from './redirectRoutes';
import staticPageRoutes from './staticPageRoutes';

const Home = lazy(() => import('views/Home'));
const Explore = lazy(() => import('views/Explore'));

const mainRoute = (isAuth) => [
  {
    path: '/',
    element: (
      <Suspense fallback={<PageSpin />}>
        <AppRouterLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: PATHS.EXPLORE,
        element: <Explore />,
      },
      ...staticPageRoutes,
      ...entitiesHomepageRoutes,
      ...authenticatedUserRouter(isAuth),
      ...notAuthenticatedRoute(isAuth),
    ],
  },
  ...redirectRoutes,
];

export default mainRoute;
