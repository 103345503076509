import HelmetComponent from 'Components/Helmet';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGetNotificationsSettingsQuery, useUpdateNotificationSettingsMutation } from 'store/services/notifications';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import Skeleton from 'style-guide/Skeleton';
import Table from 'style-guide/Table';
import Title from 'style-guide/Typography/Title';
import NotificationsSettingsWrapper from '../style/NotificationSettingsWrapper';
import getSettingsColumns from './getSettingsColumns';

const NotificationsSettings = () => {
  const { data, isLoading } = useGetNotificationsSettingsQuery();

  const [updateNotificationSettings] = useUpdateNotificationSettingsMutation();

  const { notificationsSettingsData, response } = data || {};

  const { control, reset, handleSubmit } = useForm();

  useEffect(() => {
    if (notificationsSettingsData) {
      reset({ settings: notificationsSettingsData });
    }
  }, [notificationsSettingsData, reset]);

  const onFormSubmit = (formData) => {
    updateNotificationSettings(formData);
  };

  return (
    <NotificationsSettingsWrapper>
      <HelmetComponent title='Notifications Settings' />
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Title className='d-flex justify-content-center'>Administrator Notifications</Title>
        <div className='table'>
          {isLoading ? (
            <Skeleton skeleton={{ count: 14, height: 40 }} />
          ) : (
            <Table
              columns={getSettingsColumns({ control })}
              scroll={{ x: 768 }}
              rowKey={(row) => row.id}
              data={response.admins}
            />
          )}
        </div>
        <Title className='d-flex justify-content-center'>Learner Notifications</Title>
        <div className='table'>
          {isLoading ? (
            <Skeleton skeleton={{ count: 9, height: 40 }} />
          ) : (
            <Table
              columns={getSettingsColumns({ control })}
              scroll={{ x: 768 }}
              rowKey={(row) => row.id}
              data={response.learners}
            />
          )}
        </div>
        <Button variant='primary' type='submit' className='ph--32'>
          Save
        </Button>
      </Form>
    </NotificationsSettingsWrapper>
  );
};

export default NotificationsSettings;
