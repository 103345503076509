import { ENTITY_TYPES, USER_COURSE_TYPES } from 'common';
import { loadState } from 'configs/localStorage';
import PATHS from 'constants/paths';
import { generatePath } from 'react-router-dom';

const { member, organizer } = USER_COURSE_TYPES.userCourseRole;

const isAuthUserOptions = (isAuthUser, user) =>
  isAuthUser
    ? [
        {
          name: 'Member',
          path: `${generatePath(PATHS.DASHBOARD, { category: ENTITY_TYPES.LEARNING_COMMUNITY.key, role: member })}`,
        },
        {
          name: 'Organizer',
          path: `${generatePath(PATHS.DASHBOARD, {
            category: ENTITY_TYPES.LEARNING_COMMUNITY.key,
            role: organizer,
          })}`,
        },
        {
          name: 'Invite',
          path: `${generatePath(PATHS.DASHBOARD, {
            category: ENTITY_TYPES.INVITED.key,
            role: member,
          })}`,
        },
        {
          name: 'Create now',
          isCreateEntities: true,
        },
        {
          line: true,
        },
        {
          name: 'Profile',
          path: generatePath(PATHS.PROFILE, { userName: user.userName }),
        },
        {
          name: 'Favorites',
          path: generatePath(PATHS.EXPLORE, { category: 'favorites' }),
        },
        {
          name: ENTITY_TYPES.MEMBER.plural,
          path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.MEMBER.key }),
        },
        {
          name: 'Log out',
          path: '/',
        },
      ]
    : [];

const defaultHeaderOptions = [
  {
    name: ENTITY_TYPES.LEARNING_COMMUNITY.plural,
    path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.LEARNING_COMMUNITY.key }),
  },
  {
    name: ENTITY_TYPES.DEPARTMENT.plural,
    path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.DEPARTMENT.key }),
  },
  {
    name: ENTITY_TYPES.ORGANIZATION.plural,
    path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.ORGANIZATION.key }),
  },
];

const headerOptions = () => {
  const { adminUser } = loadState();
  const isAuthSuperAdmin = adminUser?.accessToken;
  return isAuthSuperAdmin
    ? [
        {
          name: 'Admin Dashboard',
          path: '/admin/user',
        },
        ...defaultHeaderOptions,
      ]
    : defaultHeaderOptions;
};

const HEADER_OPTIONS = (isAuthUser, user) => [...headerOptions(), ...isAuthUserOptions(isAuthUser, user)];

export default HEADER_OPTIONS;
