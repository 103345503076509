import { Controller } from 'react-hook-form';
import Button from 'style-guide/Button';
import DatePicker from 'style-guide/DatePicker';
import FormItem from 'style-guide/FormItem';
import { Input, InputNumber } from 'style-guide/Input';
import { cardDetails } from './helper';

const PaymentForm = ({ control, errors, onToggleModal, disabled }) => (
  <div>
    <Controller
      control={control}
      name='number'
      render={({ field }) => (
        <FormItem error={errors.number}>
          <InputNumber
            placeholder={`Card Number (${cardDetails.cardDigitCount} digits)`}
            variant='number'
            label='Card Number'
            {...field}
          />
        </FormItem>
      )}
    />
    <div className='d-flex flex-row'>
      <Controller
        control={control}
        name='date'
        render={({ field: { onChange, value } }) => (
          <FormItem error={errors.date}>
            <DatePicker
              selected={value}
              onChange={onChange}
              minDate={new Date()}
              customInput={<Input label='Expiration Date' />}
              dateFormat='MM/yy'
              showMonthYearPicker
              placeholderText='MM/YY'
            />
          </FormItem>
        )}
      />
      <Controller
        control={control}
        name='cvc'
        render={({ field }) => <Input {...field} label='CVV' placeholder='CVV' error={errors.cvc} />}
      />
    </div>
    <div className='d-flex justify-content-center mt--16'>
      <Button variant='secondary' onClick={onToggleModal} className='ph--40'>
        Cancel
      </Button>
      <Button variant='primary' type='submit' className='ph--32 ml--16' disabled={disabled}>
        Purchase
      </Button>
    </div>
  </div>
);

export default PaymentForm;
