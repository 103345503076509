import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const RightSectionWrapper = styled.div`
  display: none;
  &.rightMenuBox {
    display: flex;
    justify-content: end;
    &.mask {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
      height: 100%;
      z-index: 1;
    }
    .rightMenuContent {
      margin-top: 60px;
      .down-icon {
        padding-left: 8px;
        path {
          fill: ${({ theme }) => theme.colors.dark[80]};
        }
      }
      .name {
        height: 40px;
        color: ${({ theme }) => theme.colors.dark[80]};
        margin: 2px 5px;
        &:hover {
          text-decoration: none;
        }
      }
      .line {
        margin: 20px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.dark[15]};
      }
    }
    .rightMenu {
      overflow: auto;
      transition: width 0.25s;
      position: fixed;
      background-color: ${({ theme }) => theme.colors.background[100]};
      color: ${({ theme }) => theme.colors.dark[100]};
      height: 100%;
      z-index: 1000;
      width: 100%;
      @media only screen and (min-width: ${SCREEN_SIZES.MD}px) {
        width: 420px;
      }
    }
    .w-375 {
      width: 375px;
    }
    .w-full {
      width: 100%;
    }
    @media only screen and (min-width: ${SCREEN_SIZES.XL}px) {
      display: none;
    }
  }
`;

/** @component */
export default RightSectionWrapper;
