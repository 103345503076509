import EntityCard from 'Components/NewCards/ColDepCard';
import { ENTITY_TYPES } from 'common';
import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';

const { DEPARTMENT } = ENTITY_TYPES;

const Departments = ({ data, setDEPPage, DEPPage, colSpan }) => {
  if (!data?.total || data?.total <= 0) {
    return null;
  }

  return (
    <>
      <Title variant={5} className='title'>{`${ENTITY_TYPES.DEPARTMENT.plural} (${data?.total || 0})`}</Title>
      <div className='row cards'>
        {data?.rows?.map((el) => (
          <div key={el.id} className={colSpan}>
            <EntityCard data={el} entityType={DEPARTMENT.key} />
          </div>
        ))}
      </div>
      {data?.total > data?.rows?.length ? (
        <Button
          variant='link'
          children='See More'
          className='more-button'
          onClick={() => setDEPPage({ ...DEPPage, offset: DEPPage.offset + DEPPage.limit })}
        />
      ) : null}
    </>
  );
};

export default Departments;
