import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const ExploreWrapper = styled.div`
  .entity-card-container {
    background-color: ${({ theme }) => theme.colors.white[100]};
  }
  .side-bar-content {
    background-color: ${({ theme }) => theme.colors.white[100]};
    overflow-y: auto;
    width: 300px;
    height: calc(100vh - 60px);
    border-right: 1px solid ${({ theme }) => theme.colors.dark[15]};
    padding: 20px 20px 20px 12px;

    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      height: 100vh;
      position: fixed;
      z-index: 1100;
      top: 0;
      left: 0;
    }
  }

  .body-root {
    .section-box {
      margin-top: 30px;
      .skeleton-title {
        padding: 0 0 20px 26px;
      }
    }

    .favorites-container {
      margin-top: 30px;
    }
    .title {
      margin-left: 26px;
      margin-bottom: 20px;
    }
    .card-box {
      .content-box {
        padding: 0 14px 0 18px;
        @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
          padding: 0;
        }
      }

      @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
        height: ${({ $exploreMembersPage }) => ($exploreMembersPage ? 'calc(100vh - 240px)' : 'calc(100vh - 200px)')};
      }
    }
    .cards {
      max-width: 1400px;
      row-gap: 16px;
      margin-bottom: 20px;
      padding: 0 14px 0 18px;
      .skeletons-container {
        row-gap: 16px;
      }
      .card {
        padding: 0 8px;
      }
    }
    .more-button {
      padding: 0;
      margin: 0 0 16px 26px;
    }
  }
  .all-container {
    margin-top: 30px;
  }
  .content-root {
    padding-top: 0;
    padding-left: 0;
    width: 100%;
    .main {
      &.category-name {
        &.organizer {
          margin-top: 20px;
        }
        @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
          margin: 20px 0 20px 16px;
        }
      }
    }
    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      padding-left: 4px;
    }
  }
  .card-box-all {
    height: calc(100vh - 125px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .explore-skeleton-cards-box {
    .section-box {
      margin-top: 30px;
      margin-bottom: 70px;
    }
    .skeleton-title {
      padding: 0 0 20px 26px;
    }
    .skeletons-container {
      row-gap: 16px;
    }
    .cards {
      padding: 0 14px 0 18px;
    }
    .card {
      padding: 0 8px;
    }
  }

  .search-box {
    padding: 30px 24px 0;
  }
  .active-button {
    text-decoration: underline;
  }
  .letter-button {
    padding: 0 14px;
  }
  .letters-section {
    padding: 0 14px 32px 18px;
    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      padding: 0;
    }
    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      overflow: auto;
      padding-bottom: 4px;
    }
  }
  .side-bar-container {
    position: fixed;
    margin-top: 70px;
    top: 0;
    left: 0px;
    .side-bar-content {
      border-right: none !important;
    }
  }
  .card-position {
    margin-left: 333px;
  }
`;

export default ExploreWrapper;
