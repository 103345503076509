import { date, number, object } from 'yup';
import { cardDetails } from './helper';

export const cardDetailsSchema = {
  number: number()
    .typeError('Please enter a valid credit card number')
    .test(
      'len',
      `This field has to be exactly ${cardDetails.cardDigitCount} digits`,
      (val) => val.toString().length === cardDetails.cardDigitCount
    )
    .required(),
  cvc: number()
    .typeError('Please enter a valid CVV number')
    .test(
      'len',
      `This field has to be exactly ${cardDetails.cvcDigitCount} digits`,
      (val) => val.toString().length === cardDetails.cvcDigitCount
    )
    .required(),
  date: date().typeError('Please enter a valid date').required(),
};

const schema = object().shape(cardDetailsSchema);

export default schema;
