const MODAL_TYPES = {
  RESET_PASSWORD: 'RESET_PASSWORD',
  EDIT_PROFILE: 'EDIT_PROFILE',
  ADD_SOCIAL_MEDIA: 'ADD_SOCIAL_MEDIA',
  EDIT_PAYMENT_METHOD: 'EDIT_PAYMENT_METHOD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  EDIT_BIO: 'EDIT_BIO',
  VIEW_MEMBERSHIP: 'VIEW_MEMBERSHIP',
  FILE_MANAGER: 'FILE_MANAGER',
  CONFIRMATION: 'CONFIRMATION',
  PRESENTATION: 'PRESENTATION',
  JOIN_ENTITIES: 'JOIN_ENTITIES',
  ENTITY_REVIEW: 'ENTITY_REVIEW',
  LEARNING_COMMUNITY_REVIEW: 'LEARNING_COMMUNITY_REVIEW',
  DEPARTMENT_REVIEW: 'DEPARTMENT_REVIEW',
  ORGANIZATION_REVIEW: 'ORGANIZATION_REVIEW',
  RESOURCES: 'RESOURCES',
  IDLE_TIMER: 'IDLE_TIMER',
  CLONE_ENTITY: 'CLONE_ENTITY',
  USER_MODIFY: 'USER_MODIFY',
  JOIN_USER_TO_COURSES: 'JOIN_USER_TO_COURSES',
  VIEW_COURSE_SETTINGS: 'VIEW_COURSE_SETTINGS',
  STATIC_PAGES_MODAL: 'STATIC_PAGES_MODAL',
  ADMIN_ADD_USER_MODAL: 'ADMIN_ADD_USER_MODAL',
};

export default MODAL_TYPES;
