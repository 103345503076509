import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CREATE_NEW_ENTITIES from 'constants/DropdownOptions/createNewEntities';
import HEADER_OPTIONS from 'constants/DropdownOptions/header';
import usePrevious from 'hooks/usePrevious';

import Button from 'style-guide/Button';
import { Down } from 'style-guide/Icons';

import classNames from 'classnames';
import useLogout from 'store/services/helperHooks/useLogout';
import Dropdown from './style/Dropdown';
import RightSectionWrapper from './style/RightSectionWrapper';

const RightSection = ({ isOpen, close }) => {
  const navigate = useNavigate();
  const isAuthUser = useSelector((state) => state.authorizationsSlice.isAuth);

  const { pathname } = useLocation();
  const prevPathName = usePrevious(pathname);
  const [logOut] = useLogout();

  const profileSlice = useSelector((state) => state?.profileSlice);

  const { userData: user } = profileSlice;

  useEffect(() => {
    if (prevPathName !== undefined && prevPathName !== pathname) {
      close();
    }
  }, [pathname, prevPathName, close]);

  return (
    <RightSectionWrapper className={classNames({ 'rightMenuBox mask': isOpen })}>
      <div onClick={(event) => event.stopPropagation()} className='rightMenu'>
        {isOpen ? (
          <div className='rightMenuContent'>
            {HEADER_OPTIONS(isAuthUser, user).map(({ name, path, line, isCreateEntities }, i) => {
              if (line) {
                const key = `list${i}`;
                return <div className='line' key={key} />;
              }
              return (
                <div key={name}>
                  {isCreateEntities ? (
                    <Dropdown
                      trigger='click'
                      placement='bottomRight'
                      options={CREATE_NEW_ENTITIES}
                      onClickItem={(key) => navigate(`/${key}/manage`)}
                    >
                      <Button variant='link' className='name'>
                        {name} <Down width={12} height={12} className='down-icon' />
                      </Button>
                    </Dropdown>
                  ) : (
                    <Link to={path}>
                      <Button
                        onClick={() => {
                          if (name === 'Log out') {
                            logOut();
                          }
                        }}
                        variant='link'
                        className='name'
                      >
                        {name}
                      </Button>
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </RightSectionWrapper>
  );
};

export default RightSection;
