import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { ENTITY_TYPES, USER_COURSE_TYPES } from 'common';
import EXPLORE_OPTIONS from 'constants/DropdownOptions/explore';
import PATHS from 'constants/paths';
import Button from 'style-guide/Button';
import DropdownV2 from 'style-guide/DropdownV2';
import { Down } from 'style-guide/Icons';

const { member } = USER_COURSE_TYPES.userCourseRole;
const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const EntitiesButtons = ({ className }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAuthUser = useSelector((state) => state.authorizationsSlice.isAuth);
  const dropdownOptions = EXPLORE_OPTIONS.filter((el) => el.value !== 'favorites');

  return (
    <>
      <DropdownV2
        onClickItem={(value) => {
          const url = generatePath(PATHS.EXPLORE, { category: value });
          if (pathname !== url) {
            navigate(url);
          }
        }}
        options={dropdownOptions}
        space={10}
      >
        <Button
          variant='text'
          suffix={<Down className='down' width={14} height={16} />}
          className={classNames('explore ml--16', {
            activeDropdown: pathname === PATHS.EXPLORE,
          })}
        >
          Explore
        </Button>
      </DropdownV2>
      {isAuthUser ? (
        <>
          <Button
            to={`${generatePath(PATHS.DASHBOARD, { category: LEARNING_COMMUNITY.key, role: member })}`}
            variant='text'
            className={classNames('header-button ml--8', className)}
          >
            Member
          </Button>
          <Button
            to={`${generatePath(PATHS.LEARNING_COMMUNITIES_DASHBOARD)}`}
            variant='text'
            className={classNames('header-button ml--8', className)}
          >
            Organizer
          </Button>

          {/* ToDo: remove Media Library comment after adding functionality */}

          {/* <Button variant='text' className='header-button ml--8' disabled>
            Media library
          </Button> */}
        </>
      ) : null}
    </>
  );
};

export default EntitiesButtons;
