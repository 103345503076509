import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';

import { ENTITY_TYPES } from 'common';
import { Eye, Lock, Paperclip, Pencil, Play, Settings, Trash } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const coursePath = (item) => `/${LEARNING_COMMUNITY.key}/${item.id}/${item.urlName}/organizer/homepage`;
const courseSettingsPath = (item) => `/${LEARNING_COMMUNITY.key}/manage/edit-settings/${item.id}/${item.urlName}`;

const setModalTitleText = (modalTitle, item) => {
  switch (modalTitle) {
    case 'delete':
      return 'You want to delete the course?';
    case 'archive':
      return `You want to ${item.isArchive === true ? 'unarchive' : 'archive'} the course?`;
    default:
      `You want to ${item.isDemo ? 'unset' : 'set'} demo?`;
  }
};

const showConfirmationModal = (dispatch, deleteCourse, updateArchive, updateDemo, item, title) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.CONFIRMATION,
      params: {
        modal: {
          title: <Title>Are you sure</Title>,
        },
        body: {
          title: (
            <Text $variant={2} $dark={100}>
              {setModalTitleText(title, item)}
            </Text>
          ),
          onOk: () => {
            switch (title) {
              case 'delete':
                deleteCourse({ id: item.id });
                break;
              case 'archive':
                updateArchive({
                  courseId: item.id,
                  isArchive: !item.isArchive,
                  mode: 'course',
                });
                break;
              default:
                updateDemo({ id: item.id, admin: true, isDemo: !item.isDemo });
            }
          },
        },
      },
    })
  );
};

const showSettingsModal = (dispatch, id) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.VIEW_COURSE_SETTINGS,
      params: {
        modal: {
          title: (
            <div className='col-12'>
              <Title>Allow comment</Title>
            </div>
          ),
        },
        body: {
          id,
        },
      },
    })
  );
};
export const getColumns = ({ setCourseUserData, dispatch, deleteCourse, updateArchive, updateDemo }) => [
  {
    title: <SearchDropdown title='Name' name='name' s />,
    dataIndex: 'name',
    key: 'name',
    width: 210,
  },
  {
    title: <Title variant={5}>Administrator</Title>,
    dataIndex: 'administratorName',
    key: 'administratorName',
    width: 150,
  },
  {
    title: <DropDownFilter name='accessTypeId' firstParam='Private' secondParam='Public' />,
    dataIndex: 'accessTypeId',
    key: 'accessTypeId',
    width: 150,
  },
  {
    title: <Title variant={5}>Payment Type</Title>,
    dataIndex: 'paymentTypeId',
    key: 'paymentTypeId',
    width: 150,
  },
  {
    title: <SearchDropdown title='Price' name='price' />,
    dataIndex: 'price',
    key: 'price',
    width: 150,
  },
  {
    title: <Title variant={5}>Archive</Title>,
    dataIndex: 'isArchive',
    key: 'isArchive',
    width: 150,
    render: (_index, item) => (
      <div className='row'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <Text>{item.isArchive === true ? 'Archived' : ''}</Text>
        </div>
      </div>
    ),
  },
  {
    title: <Title variant={5}>Actions</Title>,
    dataIndex: 'Actions',
    key: 'Actions',
    render: (_index, item) => (
      <div className='d-flex align-items-center'>
        <Tooltip placement='topRight' overlay='View Course'>
          <Eye className='eye pointer action-icon' onClick={() => setCourseUserData(item, coursePath)} />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Edit Course'>
          <Pencil className='pencil pointer action-icon' onClick={() => setCourseUserData(item, courseSettingsPath)} />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Remove Course'>
          <Trash
            className='trash pointer action-icon'
            onClick={() => showConfirmationModal(dispatch, deleteCourse, updateArchive, updateDemo, item, 'delete')}
          />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Allow comment'>
          <Settings
            className='settings pointer action-icon'
            onClick={() => {
              showSettingsModal(dispatch, item.id);
            }}
          />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Archive Course'>
          <Lock
            className='lock pointer action-icon'
            onClick={() => showConfirmationModal(dispatch, deleteCourse, updateArchive, updateDemo, item, 'archive')}
          />
        </Tooltip>
        {item.isDemo ? (
          <Tooltip placement='topRight' overlay='Unset demo'>
            <Play
              className='pointer action-icon'
              onClick={() => showConfirmationModal(dispatch, deleteCourse, updateArchive, updateDemo, item, 'demo')}
            />
          </Tooltip>
        ) : (
          <Tooltip placement='topRight' overlay='Set demo'>
            <Paperclip
              className='paperclip pointer'
              onClick={() => showConfirmationModal(dispatch, deleteCourse, updateArchive, updateDemo, item, 'demo')}
            />
          </Tooltip>
        )}
      </div>
    ),
    width: 170,
  },
];

export const COURSES_CONST = { NAME: 'name', PRICE: 'price', COURSES: 'courses' };
